import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { BeachAccessOutlined } from '@mui/icons-material';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  GroupVacationsContractInput,
  VacationsAccrualPeriodSummary,
  fetchPostGroupVacations,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import { UI_TYPE } from '@octopus/libs/forms';
import {
  CreateScheduleRule,
  doesNotHaveAvailableDaysPolicies,
  scheduleErrorMessages,
} from '@octopus/vacations-types';

import { BackButton } from '../../../modules/components/BackButton';
import { Form } from '../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../modules/form/useFormFromDefinition';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { ActionBar } from '../new/PageActionsBar';
import { columnsByTab } from '../utils/columns';
import { VacationsTabs } from '../utils/types';

type NewVacationsGroupProps = {
  organizationId: string;
};

type Policy = 'paidLeave' | 'goBackToWork';
type Form = {
  startDate: string;
  endDate: string;
  paymentDate: string;
  policy: Policy;
};

export const NewVacationsGroup: React.FC<NewVacationsGroupProps> = ({
  organizationId,
}: NewVacationsGroupProps) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<Form>({
    startDate: '',
    endDate: '',
    paymentDate: '',
    policy: 'paidLeave',
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [vacationsPolicyByContract, setVacationsPolicyByContract] = useState<
    Record<string, Policy>
  >({});

  const setDoesNotHaveAvailableDaysPolicy = (id: string, policy: Policy) => {
    setVacationsPolicyByContract({
      ...vacationsPolicyByContract,
      [id]: policy,
    });
  };

  const searchVacationsAccrualPeriods = async () => {
    let pageParam = 0;
    let data: VacationsAccrualPeriodSummary[] = [];
    let hasNext = false;
    do {
      const result = await fetchSearchVacationsAccrualPeriods({
        pathParams: { organizationId },
        body: {
          pagination: {
            page: pageParam,
            size: 100,
          },
        },
      });
      data = [...data, ...result.data];
      pageParam++;
      hasNext = !!result.size;
    } while (hasNext);
    return data;
  };

  const vacationsAccrualPeriods = useQuery({
    queryKey: ['fetchSearchVacationsAccrualPeriods', organizationId],
    queryFn: searchVacationsAccrualPeriods,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!organizationId,
  });

  const vacationsGroupForm = useFormFromDefinition(
    [
      {
        label: 'Data de início',
        type: z.string(),
        name: 'startDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Data de fim',
        type: z.string(),
        name: 'endDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Data de pagamento',
        type: z.string(),
        name: 'paymentDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Políticas de Dias Indisponíveis',
        type: z.string(),
        name: 'policy',
        uiType: UI_TYPE.SELECT,
        options: [
          {
            label: 'Licença Remunerada',
            value: doesNotHaveAvailableDaysPolicies.paidLeave,
          },
          {
            label: 'Voltar ao Trabalho',
            value: doesNotHaveAvailableDaysPolicies.goBackToWork,
          },
        ],
      },
    ],
    {
      id: 'vacations-group-form',
      persistLocal: false,
      onSubmit: async () => {
        const { startDate, paymentDate, endDate, policy } = form;

        const contracts: GroupVacationsContractInput[] = selectedContracts.map(
          (contractId) => {
            return {
              contractId,
              doesNotHaveAvailableDaysPolicy:
                vacationsPolicyByContract?.[contractId] || (policy as Policy),
            };
          },
        );

        setIsLoading(true);
        await fetchPostGroupVacations({
          body: {
            startDate,
            endDate,
            paymentDate,
            contracts,
          },
          pathParams: { organizationId },
        })
          .then(() => {
            showSnackbar({
              isOpen: true,
              Message: 'Férias coletivas agendadas com sucesso',
            });
            navigate('/vacations-group');
          })
          .catch((error) => {
            const message = Object.entries(error.stack.details).map(([key]) => {
              const errorKey = key.split('/')[1] as CreateScheduleRule;
              return scheduleErrorMessages[errorKey]?.description;
            })?.[0];

            showSnackbar({
              isOpen: true,
              Message: message,
              variant: 'error',
            });
          });
        setIsLoading(false);
      },
      useNewParser: true,
    },
  );

  const { startDate, endDate, paymentDate, policy } =
    vacationsGroupForm.payloadValue as Form;

  useEffect(() => {
    setForm({
      startDate,
      paymentDate,
      endDate,
      policy,
    });
  }, [policy, startDate, endDate]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Box>
        <BackButton />
      </Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="group-vacations-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Férias Coletivas</Typography>
        </Box>

        <Box>
          {vacationsAccrualPeriods.isLoading ? (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          ) : (
            <Box>
              <Box>
                <Form
                  metaForm={vacationsGroupForm.metaForm}
                  payloadForm={vacationsGroupForm.payloadForm}
                >
                  <Form.Layout>
                    <Form.Field for={vacationsGroupForm.fields.startDate} />
                    <Form.Field for={vacationsGroupForm.fields.endDate} />
                    <Form.Field for={vacationsGroupForm.fields.paymentDate} />
                    <Form.Field for={vacationsGroupForm.fields.policy} />
                  </Form.Layout>
                </Form>
              </Box>
              <Box
                mt={4}
                display="flex"
                sx={{ height: '100vh', width: '100%' }}
              >
                <Box flexGrow={1}>
                  <DataGrid
                    getRowId={(row) => row.contractId}
                    rows={vacationsAccrualPeriods.data}
                    columns={[
                      ...columnsByTab[VacationsTabs.people],
                      doesNotHaveAvailableDaysPolicyColumn(
                        setDoesNotHaveAvailableDaysPolicy,
                        policy as string,
                        vacationsPolicyByContract,
                      ),
                    ]}
                    checkboxSelection
                    components={{
                      Toolbar: GridToolbarQuickFilter,
                    }}
                    componentsProps={{
                      toolbar: {
                        sx: { p: 2 },
                        placeholder: 'Filtrar',
                      },
                    }}
                    onSelectionModelChange={(newSelection) => {
                      console.log({ newSelection });
                      setSelectedContracts(newSelection);
                    }}
                    selectionModel={selectedContracts}
                  />
                </Box>
              </Box>

              <Box mt={5} display="flex" justifyContent="end">
                <ActionBar>
                  <ActionBar.Action
                    sx={{ minWidth: '157px' }}
                    type="submit"
                    form={vacationsGroupForm.id}
                    isLoading={isLoading}
                    disabled={selectedContracts.length === 0}
                  >
                    {'Agendar férias'}
                  </ActionBar.Action>
                </ActionBar>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const doesNotHaveAvailableDaysPolicyColumn = (
  setDoesNotHaveAvailableDaysPolicy: (id: string, policy: Policy) => void,
  defaultSelectedValue: string,
  vacationsPolicyByContract: Record<string, Policy>,
): GridColDef<VacationsAccrualPeriodSummary> => ({
  field: 'doesNotHaveAvailableDaysPolicy',
  headerName: 'Políticas de Dias Indisponíveis',
  flex: 1,
  valueGetter: (
    params: GridValueGetterParams<VacationsAccrualPeriodSummary>,
  ) => {
    return params.row;
  },
  renderCell: (params) =>
    PolicyColumn(
      setDoesNotHaveAvailableDaysPolicy,
      defaultSelectedValue,
      vacationsPolicyByContract,
      params,
    ),
});

const PolicyColumn = (
  setDoesNotHaveAvailableDaysPolicy: (id: string, policy: Policy) => void,
  defaultSelectedValue: string,
  vacationsPolicyByContract: Record<string, Policy>,
  params: GridRenderCellParams<VacationsAccrualPeriodSummary>,
) => {
  const { contractId } = params.value;
  const selectedValue = vacationsPolicyByContract?.[contractId];
  const value = selectedValue || defaultSelectedValue;

  const handleChange = (event: SelectChangeEvent<string>) => {
    setDoesNotHaveAvailableDaysPolicy(contractId, event.target.value as Policy);
  };
  return (
    <Select
      value={value || ''}
      onChange={handleChange}
      fullWidth
      variant="outlined"
      size="small"
    >
      {Object.entries(doesNotHaveAvailableDaysPolicies).map(([key, label]) => (
        <MenuItem key={key} value={label} selected={value === label}>
          {label === 'paidLeave' ? 'Licença Remunerada' : 'Voltar ao Trabalho'}
        </MenuItem>
      ))}
    </Select>
  );
};
